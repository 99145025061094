<template>
  <page-header-wrapper ref="container">
    <div class="a-card">
      <CouponSearch @resetData="resetSearchForm" @handleAdd="handleAdd"/>
      <div class="table-operator">
        <a-button
          style="display: none"
          type="dashed"
          @click="tableOption"
        >{{ (optionAlertShow && '关闭') || '开启' }} alert
        </a-button>
        <!-- <a-dropdown v-action:edit v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" click="handleDeleteSelected">
              <a-icon type="delete" />
              删除
            </a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px">
            批量操作
            <a-icon type="down" />
          </a-button>
        </a-dropdown> -->
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :scroll="{ x: true }"
        :rowSelection="options.rowSelection"
        :pagination="{ showSizeChanger: true, showQuickJumper: true, pageSize: 20, total: 50 }"
        :row-key="(record) => record.couponId"
      >
        <a-table-column title="操作" align="center">
          <span slot-scope="record">
            <a @click="handleEdit(record)">{{ $t('button.edit') }}</a>
            <a-divider type="vertical"/>
            <a-dropdown>
              <a class="ant-dropdown-link"> 更多 <a-icon type="down"/> </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="record.approvalStatus!=='已通过'">
                  <a @click="handleApproval(record)">{{ $t('button.approval') }}</a>
                </a-menu-item>
                <a-menu-item
                  v-if="record.couponStatus==='草拟中' && record.approvalStatus==='已通过'">
                  <a @click="handlePublish(record)">{{ $t('button.publish') }}</a>
                </a-menu-item>
                <a-menu-item v-if="record.couponStatus==='已发布'">
                  <a @click="handlePause(record)">{{ $t('button.pause') }}</a>
                </a-menu-item>
                <a-menu-item v-if="record.couponStatus==='已暂停'">
                  <a @click="handleRestore(record)">{{ $t('button.restore') }}</a>
                </a-menu-item>
                <a-menu-item v-if="record.couponStatus==='已发布'">
                  <a @click="handleClose(record)">{{ $t('button.close') }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table-column>
        <!-- 优惠券码 -->
        <a-table-column :title="$t('columnName.couponCode')" align="left">
          <template slot-scope="record">{{record.couponCode}}</template>
        </a-table-column>
        <!-- 优惠券名称 -->
        <a-table-column :title="$t('columnName.couponName')" align="left">
          <template slot-scope="record">{{record.couponName}}</template>
        </a-table-column>
        <!-- 优惠券类型编码 -->
        <a-table-column :title="$t('columnName.couponTypeCode')" align="left">
          <template slot-scope="record">{{record.couponTypeCode}} - {{record.couponTypeName}}</template>
        </a-table-column>
        <!-- 市场编码 -->
        <a-table-column :title="$t('columnName.marketplaceCode')" align="left">
          <template slot-scope="record" >{{record.marketplaceCode}} - {{record.marketplaceName}}</template>
        </a-table-column>
        <!-- 开始时间 -->
        <a-table-column :title="$t('columnName.startTime')" align="left">
          <template slot-scope="record" v-if="record.timeLimitType === '固定期间'">{{formatDateTime(record.startTime)}}</template>
        </a-table-column>
        <!-- 结束时间 -->
        <a-table-column :title="$t('columnName.endTime')" align="left">
          <template slot-scope="record" v-if="record.timeLimitType === '固定截止时间' || record.timeLimitType === '固定期间'">{{formatDateTime(record.endTime)}}</template>
        </a-table-column>
        <!-- 折扣信息 -->
        <a-table-column :title="$t('columnName.discount')" align="left">
          <template slot-scope="record" >
            <div v-if="record.discount.shippingFeeAmount ">
            {{ $t('field.shippingFeeAmount') }}:&nbsp; {{ record.discount.shippingFeeAmount / 100 + " $" }}<br>
          </div>
          <div v-if="record.discount.shippingFeeAmountPercent ">
            {{ $t('field.shippingFeeAmountPercent') }}:&nbsp; {{ record.discount.shippingFeeAmountPercent }}<br>
          </div>
          <div v-if="record.discount.pricePercent ">
            {{ $t('field.pricePercent') }}:&nbsp; {{ record.discount.pricePercent }}<br>
          </div>
          <div v-if="record.discount.minTotalAmount ">
            {{ $t('field.minTotalAmount') }}:&nbsp; {{ record.discount.minTotalAmount / 100 + " $" }}<br>
          </div>
          <div v-if="record.discount.priceAmount ">
            {{ $t('field.priceAmount') }}:&nbsp; {{ record.discount.priceAmount / 100 + " $" }}<br>
          </div>
          </template>
        </a-table-column>
        <!-- 优惠券状态 -->
        <a-table-column :title="$t('columnName.couponStatus')" align="left">
          <template slot-scope="record">{{getCode('couponStatus', record.couponStatus)}}</template>
        </a-table-column>
        <!-- 审批状态 -->
        <a-table-column :title="$t('columnName.approvalStatus')" align="left">
          <template slot-scope="record">{{getCode('approvalStatus', record.approvalStatus)}}</template>
        </a-table-column>
        <!-- 创建人 -->
        <a-table-column :title="$t('columnName.creatorName')" align="left">
          <template slot-scope="record">{{record.creatorName}}<br />{{formatDateTime(record.createTime)}}</template>
        </a-table-column>
        <!-- 更新人 -->
        <a-table-column :title="$t('columnName.updaterName')" align="left">
          <template slot-scope="record">{{record.updaterName}}<br />{{formatDateTime(record.updateTime)}}</template>
        </a-table-column>
      </s-table>
    </div>
    <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
    <ApprovalEditor ref="approvalEditor" @handleOk="handleApprovalEditOk"/>
    <a-drawer
      width="70%"
      placement="right"
      :closable="false"
      :visible="showDetailDrawer"
      @close="onDetailDrawerClose">
      <CouponDetail ref="couponDetail" :options="detailOptions" :visible="showDetailDrawer"></CouponDetail>
    </a-drawer>
  </page-header-wrapper>

</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  approvalCoupon,
  deleteCoupon,
  disableCoupon,
  enableCoupon,
  saveCouponData,
  searchCoupon
} from '@/api/marketing/coupon'
import { STable } from '@/components'
import CommentEditor from '@/components/comment/CommentEditor'
import ApprovalEditor from '@/components/approval/ApprovalEditor'
import CouponSearch from '@/views/marketing/CouponSearch'
import CouponDetail from '@/views/marketing/CouponDetail'

export default {
  name: 'CouponList',
  components: {
    CommentEditor,
    ApprovalEditor,
    STable,
    CouponSearch,
    CouponDetail
  },
  data () {
    return {
      confirmEditLoading: false,
      // 查询参数
      queryParam: {},
      detailOptions: {},
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getCoupon(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      couponModalShow: false,
      couponData: {
        picList: [],
        detailPicDisplayList: [],
        listPicUrl: '',
        spuList: []
      },
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      showDetailDrawer: false
    }
  },
  created () {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted () {
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchCoupon(Object.assign(parameter, this.queryParam)).then((res) => {
        console.log('loadData->searchCoupon.parameter', parameter, res)
        return res
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd (record) {
      // 跳转到编辑页面
      this.$router.push({
        path: '/marketing/CouponEdit',
        name: 'CouponEdit',
        query: {
          type: 'add'
        }
      })
    },
    handleEdit (record) {
      // 跳转到编辑页面
      this.$router.push({
        path: '/marketing/CouponEdit',
        name: 'CouponEdit',
        query: {
          type: 'edit',
          couponId: record.couponId
        }
      })
    },
    handleDetail (record) {
      this.detailOptions = {
        couponId: record.couponId
      }
      this.showDetailDrawer = true
      this.$nextTick(() => {
        this.$refs.couponDetail.loadData(this.detailOptions)
      })
    },
    onDetailDrawerClose () {
      this.showDetailDrawer = false
    },
    handleEditCancel (record) {
      // 弹出编辑页面
      this.couponModalShow = false
    },
    handleEditOk (record) {
      const that = this
      saveCouponData(this.couponData)
        .then((res) => {
          that.$message.success(this.$t('entity.coupon.save.success'))
          this.$refs.table.refresh(true)
        })
        .catch((res) => {
          that.$message.error(this.$t('entity.coupon.save.fail'))
        })
      this.couponData = {
        picList: [],
        detailPicDisplayList: [],
        listPicUrl: ''
      }
      // 弹出编辑页面
      this.couponModalShow = false
    },

    handleDelete (record) {
      const that = this
      this.$confirm({
        title: this.$t('message.confirm.delete'),
        content: '',
        onOk () {
          console.log('OK')
          deleteCoupon({ couponId: record.couponId })
            .then((res) => {
              console.log('handleDelete->delete{domain_name}', record, res)
              that.$refs.table.refresh(true)
              that.$message.success(that.$t('entity.coupon.delete.success'))
            })
            .catch(() => {
              that.$message.error(that.$t('entity.coupon.delete.fail'))
            })
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    },
    handlePause (record) {
      this.$refs['commentEditor'].show(record, 'pause')
    },
    handleRestore (record) {
      this.$refs['commentEditor'].show(record, 'restore')
    },
    handleClose (record) {
      this.$refs['commentEditor'].show(record, 'close')
    },
    handlePublish (record) {
      this.$refs['commentEditor'].show(record, 'publish')
    },
    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableCoupon(record)
          .then((res) => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableCoupon(record)
          .then((res) => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解除锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else {
        let couponStatus = ''
        if (params.type == 'pause') {
          couponStatus = '已暂停'
        } else if (params.type == 'restore') {
          couponStatus = '已发布'
        } else if (params.type == 'publish') {
          couponStatus = '已生效'
        } else if (params.type == 'close') {
          couponStatus = '已关闭'
        } else {
          return
        }
        return saveCouponData({
          couponId: record.couponId,
          couponStatus: couponStatus,
          comment: record.comment
        }).then(res => {
          console.log('saveCouponData', record, res)
          that.$refs.table.refresh(true)
          that.$message.success('设置状态为【' + couponStatus + '】成功')
          return res
        }).catch(() => {
          that.$refs.table.refresh(true)
          that.$message.success('设置状态为【' + couponStatus + '】失败')
        })
      }
    },
    handleDisable (record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable (record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleApproval (record) {
      this.$refs['approvalEditor'].show(record)
    },
    handleApprovalEditOk (params) {
      const record = params.record
      const that = this
      return approvalCoupon({
        approvalInfo: record.comment,
        approvalStatus: record.approvalType === 'reject' ? '已拒绝' : '已通过', /** 待审核, 已通过, 已拒绝, 审核中 */
        couponId: record.couponId
      })
        .then(res => {
          console.log('handleApprovalEditOk', record, res)
          that.$refs.table.refresh(true)
          that.$message.success('审批成功')
          return res
        }).catch(() => {
          that.$refs.table.refresh(true)
          that.$message.success('审批失败')
        })
    },
    resetSearchForm (data) {
      this.queryParam = data
      this.$refs.table.refresh(true)
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
