import request from '@/utils/request'

const baseUrl = '/api'

export function saveCouponTypeData (parameter) {
  let url = baseUrl + '/marketing/couponType/save'
  if (!parameter.couponTypeId) {
    url = baseUrl + '/marketing/couponType/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchCouponType (parameter) {
  return request({
    url: baseUrl + '/marketing/couponType/search',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listCouponType (parameter) {
  return request({
    url: baseUrl + '/marketing/couponType/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getCouponTypeData (parameter) {
  return request({
    url: baseUrl + '/marketing/couponType/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableCouponType (parameter) {
  return request({
    url: baseUrl + '/marketing/couponType/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableCouponType (parameter) {
  return request({
    url: baseUrl + '/marketing/couponType/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteCouponType (parameter) {
  return request({
    url: baseUrl + '/marketing/couponType/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
