import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveCouponData (parameter) {
  let url = baseUrl + '/marketing/coupon/save'
  if (!parameter.couponId) {
    url = baseUrl + '/marketing/coupon/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchCoupon (parameter) {
  return request({
    url: baseUrl + '/marketing/coupon/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}
export function approvalCoupon (parameter) {
  return request({
    url: baseUrl + '/marketing/coupon/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}
export function listCoupon (parameter) {
  return request({
    url: baseUrl + '/marketing/coupon/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getCouponData (parameter) {
  return request({
    url: baseUrl + '/marketing/coupon/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableCoupon (parameter) {
  return request({
    url: baseUrl + '/marketing/coupon/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableCoupon (parameter) {
  return request({
    url: baseUrl + '/marketing/coupon/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteCoupon (parameter) {
  return request({
    url: baseUrl + '/marketing/coupon/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
