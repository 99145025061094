<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.couponTypeCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allow-clear="true" v-model="queryParam.couponTypeCode" mode="default" name="couponTypeName">
              <a-select-option
                v-for="(item, index) in codeList.couponTypeList"
                :key="index"
                :item="item"
                :value="item.couponTypeCode"
              >{{ item.couponTypeCode }}-{{ item.couponTypeName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.couponCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.couponCode',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.couponCode') },
                    { max: 100, message: this.$t('field.couponCode') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.couponCode"
              v-model="queryParam.couponCode"
              :placeholder="'请输入' + this.$t('field.couponCode')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.couponName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.couponName',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.couponName') },
                    { max: 100, message: this.$t('field.couponName') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.couponName"
              v-model="queryParam.couponName"
              :placeholder="'请输入' + this.$t('field.couponName')"
            />
          </a-form-item>
        </a-col>
        <template v-if="showAdvancedSearchCondition">
          <a-col :md="8" :sm="24">
            <a-form-item label="更新日期">
              <a-range-picker v-model="queryParam.updateDate" valueFormat="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
        </template>
        <a-col :md="8" :sm="24" v-if="showAdvancedSearchCondition">
          <a-form-item
            :label="$t('field.couponStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allowClear="true" mode="default" v-model="queryParam.couponStatus">
              <a-select-option
                v-for="(item, index) in codeList.status"
                :key="index"
                :value="item.value"
              >{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24" :sm="24">
          <span
            class="table-page-search-submitButtons"
            :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
          >
            <a-button icon="plus" type="primary" @click="$emit('handleAdd')">{{ $t('button.create') }}</a-button>
            <a-divider type="vertical" />
            <a-button icon="search" type="primary" @click="queryData">{{ $t('button.search') }}</a-button
            ><a-divider type="vertical" />
            <a-button style="margin-left: 8px" @click="resetData">{{ $t('button.reset') }}</a-button>
            <a @click="toggleAdvancedSearch" style="margin-left: 8px">
              {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { listMarketplace } from '@/api/system/marketplace'
import { listCouponType } from '@/api/marketing/couponType'

export default {
  name: 'CouponSearch',
  components: {},
  props: {},
  data () {
    return {
      codeList: {
        couponTypeList: [],
        couponCode: [],
        couponStatus: []
      },
      showAdvancedSearchCondition: false,
      queryParam: {},
      selectedDate: []
    }
  },
  created () {},
  watch: {
    selectedDate (val) {
      console.log('selectedDate =>', val)
    }
  },
  mounted () {
    listMarketplace({}).then(res => {
      this.codeList.marketplaceCode = res.data
    })
    listCouponType({}).then(res => {
      this.codeList.couponTypeList = res.data
    })
  },
  methods: {
    toggleAdvancedSearch () {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetData () {
      this.queryParam = {}
      this.selectedDate = []
      this.$emit('resetData', this.queryParam)
    },
    queryData () {
      console.log(this.queryParam)
      this.$emit('resetData', this.queryParam)
    }
  }
}
</script>
